/* eslint-disable global-require */

import { _getAddress, _getProvider } from './ethereum';
import { useEffect } from 'react';
import { ERRORS } from "./errors";
import { WolfMarket } from './modules/WolfMarket';
import { Build } from './modules/Build';
import { BuybackOrder } from './modules/BuybackOrder';

import { Withdraw } from './modules/Withdraw';


/* 正在登录 */


// eslint-disable-next-line import/no-mutable-exports
export let LoginUser: {
  displayName: string;
  email: string;
  account: string;
  emailVerified: boolean;
  photoURL: string;
  uid: string;
  phoneNumber: string;
  providerData: any[];
  accessToken: string;
} = {
  displayName: "",
  email: "",
  account: "",
  emailVerified: false,
  photoURL: "",
  uid: "",
  phoneNumber: "",
  providerData: [],
  accessToken: "",
}



/* 聚合登录登录信息 */

export const loginOut = async function () {
  // try{
  //   firebase.auth().signOut();
  // } catch(e) {
  //   console.log('err', e)
  // }

  // User is signed out.
  LoginUser = {
    displayName: "",
    email: "",
    account: "",
    emailVerified: false,
    photoURL: "",
    uid: "",
    phoneNumber: "",
    providerData: [],
    accessToken: "",
  }


  localStorage.removeItem('LoginUser');
  localStorage.setItem('XAccessToken', "");
  localStorage.setItem('userInfo_userid', "");

};
export const loginInEth = async function (count) {

  // const count = await _getAddress();
  if (count) {
    LoginUser.email = count;
    /*     const provider = _getProvider();
        const signature = await provider?.getSigner().signMessage('Login Hash')
        console.log("signature", signature);
        if (!signature) return */

    /*    LoginUser.uid = signature; */
    LoginUser.email = count;

    localStorage.setItem('LoginUser', JSON.stringify(LoginUser));

    /* 登陆服务器 */
    await Login();
  }

};
//登录检查
export const isLoggedIn = function (account) {
  const login = localStorage.getItem('LoginUser');
  const accessToken = localStorage.getItem("XAccessToken")


  if (login) {
    const logObj = JSON.parse(login)
    if (logObj.email == account && accessToken) {
      console.log("heart:", logObj.email, accessToken)
      return true
    } else {
      loginInEth(account)
    }

  } else {
    //登陆失败直接尝试登录
    loginInEth(account)

  }
}




export const HASH_GAME_API = "https://api.pidaocore.com/jeecg-boot";

/* export const HASH_GAME_API = "http://localhost:8080/jeecg-boot/"; */




/* 配置数据 */
export const API_CONFIG = {
  Login: `${HASH_GAME_API}/sys/login`,
  Logout: `${HASH_GAME_API}/sys/logout`,
  Register: `${HASH_GAME_API}/auth/register`,
  SendSms: `${HASH_GAME_API}/account/sms`,
  // get my info
  UserInfo: `${HASH_GAME_API}/wolftown/getUserInfo`,
  // get account info
  getAccount: `${HASH_GAME_API}/game/getAccount`,
  // get bag info
  queryBaglist: `${HASH_GAME_API}/wolftown/queryBaglist`,
  // get Lottery info
  queryWolfLotteryGoodsList: `${HASH_GAME_API}/wolftown/queryWolfLotteryGoodsList`,

  // get Lottery info
  queryBagByName: `${HASH_GAME_API}/wolftown/queryBagByName`,
  // get Lottery info
  queryBagByType: `${HASH_GAME_API}/wolftown/queryBagByType`,
  // doLottery
  doLottery: `${HASH_GAME_API}/wolftown/doLottery`,
  // get address info
  getAddressInfo: `${HASH_GAME_API}/game/getUserAddress`,

  /*获取最近一期中奖数据 */
  getLastPrizeNumer: `${HASH_GAME_API}/game/getLastPrizeNumer`,
  /*获取用户订单数据*/
  getUserBetOrder: `${HASH_GAME_API}/game/getUserBetOrder`,
  /*获取一元购期数数据*/
  getOneUsdtNumer: `${HASH_GAME_API}/game/getOneUsdtNumer`,
  /*获取历史订单数据 */
  getUserBetOrderHistory: `${HASH_GAME_API}/game/getUserBetOrderHistory`,
  /*用户提现 */
  userWithdrawal: `${HASH_GAME_API}/game/userWithdrawal`,

  createWithdrawPidao: `${HASH_GAME_API}/game/createWithdrawPidao`,

  
  /*商品合成 */
  synthesis: `${HASH_GAME_API}/wolftown/synthesis`,
  /*商品兑奖 */
  reward: `${HASH_GAME_API}/wolftown/reward`,
  /*上链记录查询 */
  getWolfUserGoodsToChainList: `${HASH_GAME_API}/wolftown/getWolfUserGoodsToChainList`,
  /*放置土地*/
  putLand: `${HASH_GAME_API}/wolftown/putLand`,
  /*释放土地*/
  reapingLand: `${HASH_GAME_API}/wolftown/reapingLand`,

  /*土地列表信息*/
  getLandGameList: `${HASH_GAME_API}/wolftown/getLandGameList`,
  /*获取土地扩充数量*/
  getExpandNumber: `${HASH_GAME_API}/wolftown/getExpandNumber`,
  /*解除pvp冷却时间*/
  handleClearCoolingTime: `${HASH_GAME_API}/wolftown/handleClearCoolingTime`,
  /*解锁土地 */
  unlockLand: `${HASH_GAME_API}/wolftown/unlockLand`,
  /*放置战斗动物*/
  putArena: `${HASH_GAME_API}/wolftown/putArena`,

  /*查询战斗物品信息*/
  getWolfArenaGameList: `${HASH_GAME_API}/wolftown/getWolfArenaGameList`,

  /*空投数据*/
  airdrop: `${HASH_GAME_API}/wolftown/airdrop`,

  /*市场表-分页列表查询*/
  marketList: `${HASH_GAME_API}/wolftown/marketList`,

  /*市场-上架*/
  marketAdd: `${HASH_GAME_API}/wolftown/marketAdd`,

  /*市场-出价*/
  marketBuy: `${HASH_GAME_API}/wolftown/marketBuy`,
  /*PVP战斗*/
  pvp: `${HASH_GAME_API}/wolftown/pvp`,
  /*战斗记录-分页列表查询*/
  pvpList: `${HASH_GAME_API}/wolftown/pvpList`,

  /*建筑任务-分页列表查询*/
  buildList: `${HASH_GAME_API}/wolftown/buildList`,
  /*build游戏*/
  build: `${HASH_GAME_API}/wolftown/build`,
  /*build任务*/
  buildItemList: `${HASH_GAME_API}/wolftown/buildItemList`,
  transactionFlowList: `${HASH_GAME_API}/wolftown/transactionFlowList`,

  /*市场-个人挂售表*/
  myList: `${HASH_GAME_API}/wolftown/myList`,
  /*市场-个人挂售表*/
  wolfTownStoreList: `${HASH_GAME_API}/wolftown/wolfTownStoreList`,

  /*市场-下架*/
  marketRemove: `${HASH_GAME_API}/wolftown/marketRemove`,
  /*市场-商店*/
  storeBuy: `${HASH_GAME_API}/wolftown/storeBuy`,

  /*nft上链 */
  nftWithdraw: `${HASH_GAME_API}/wolftown/nftWithdraw`,
  /*bft上链记录查询 */
  getNftToChainList: `${HASH_GAME_API}/wolftown/getNftToChainList`,

  /*buyback 回购 */
  buyback: `${HASH_GAME_API}/wolftown/buyback`,
  /*buybackConfig */
  buybackConfig: `${HASH_GAME_API}/wolftown/buybackConfig`,

  /*getStakeDate */
  getStakeDate: `${HASH_GAME_API}/game/getStakeDate`,

}


/** 登录方法 */
export const Login = async () => {
  let XAccessToken = localStorage.getItem('XAccessToken');
  const login = localStorage.getItem('LoginUser');

  if (!login) return
  // eslint-disable-next-line no-eval
  const loginUser = eval(JSON.parse(login));
  if (loginUser == null || loginUser === "") {
    return;
  }

  console.log("XAccessToken", XAccessToken, "LoginUser.uid", loginUser.uid, typeof XAccessToken);
  if ((XAccessToken == null || XAccessToken === "" || XAccessToken === 'undefined') && loginUser.email !== "") {

    console.log("loginstart");

    // XAccessToken = "1";

    const response = await fetch(API_CONFIG.Login, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken || '',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: loginUser.email,
        password: loginUser.email
      }),
    })
    if (response.status === 404) {
      loginOut();
    }

    if (response.status === 200) {
      const result = await response.json();


      if (!result.success) {
        loginOut();
      } else {

        // 设置token
        localStorage.setItem('XAccessToken', result.result.token);
        localStorage.setItem('userInfo_userid', result.result.userInfo.id);
        localStorage.setItem('userInfo_username', result.result.userInfo.username);

      }
    }
  }
}

/* 获取个人信息 */
// eslint-disable-next-line consistent-return
export const getUsertInfo = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessToken", XAccessToken, "LoginUser.uid", LoginUser.uid); */
  if ((XAccessToken) && LoginUser.uid !== "") {
    const response = await fetch(`${API_CONFIG.UserInfo}?token=${XAccessToken}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
      }
    })

    console.log("response-getUsertInfo", response);
    if (response.status === 401) {
      /* loginOut(); */
    }

    if (response.status === 200) {
      const result = await response.json();
      const { userInfo } = result;

      // eslint-disable-next-line no-empty
      if (!result.success) {

      } else {
        // 设置token

        return userInfo;
      }


    }
  }

}



/* 获取余额信息 */
export const getAccountInfo = async () => {

  const acountInfo = {
    BUSD: '0',
    WTWOOL: '0',
    WTMILK: '0',
    integral: '0',
    Build: '0'
  }

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken) && uid !== "") {
    const response = await fetch(API_CONFIG.getAccount, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        uid,
      }),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 设置token
        const accountList = result.result.accountlist;
        console.log("response-accountList", accountList);

        if (accountList.length > 0) {
          for (let i = 0; i < accountList.length; i++) {
            const account = accountList[i];
            if (account.coin != null && account.coin === "BUSD") {
              acountInfo.BUSD = account.normalBalance.toString();
            }
            if (account.coin != null && account.coin === "WTWOOL") {
              acountInfo.WTWOOL = account.normalBalance.toString();
            }
            if (account.coin != null && account.coin === "WTMILK") {
              acountInfo.WTMILK = account.normalBalance.toString();
            }
            if (account.coin != null && account.coin === "integral") {
              acountInfo.integral = account.normalBalance.toString();
            }
            if (account.coin != null && account.coin === "Build") {
              acountInfo.Build = account.normalBalance.toString();
            }
          }
        }
      }
    } else if (response.status === 401) {
      await loginOut()
      console.error(response);
      // throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

  return acountInfo;
}




/* 获取用户背包数据信息 */
export const queryBaglist = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.queryBaglist, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 设置token
        const wolfUserGoodsList = result.result.wolfUserGoodsList;

        //兑换/可兑奖配置
        const goodsSynthesisConfigureList = result.result.goodsSynthesisConfigureList;
        console.log("response-accountList", wolfUserGoodsList);
        return wolfUserGoodsList;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 用户背包物品-根据名称查询 */
export const queryBagByName = async (goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  console.log("response-wolfUserGoods-XAccessToken", XAccessToken);
  if ((XAccessToken)) {
    const response = await fetch(`${API_CONFIG.queryBagByName}?token=${XAccessToken}&goodsName=${goodsName}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })

    console.log("result-response", response);
    if (response.status === 200) {
      const result = await response.json();

      if (result.success) {
        // 设置token
        const wolfUserGoods = result.result.wolfUserGoods;
        console.log("response-wolfUserGoods", wolfUserGoods);
        return wolfUserGoods;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 用户背包物品-根据类型查询 */
export const queryBagByType = async (goodsType: string, goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  console.log("response-wolfUserGoods-XAccessToken", XAccessToken);
  if ((XAccessToken)) {
    const response = await fetch(`${API_CONFIG.queryBagByType}?token=${XAccessToken}&goodsType=${goodsType}&goodsName=${goodsName}`, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })

    console.log("result-response", response);
    if (response.status === 200) {
      const result = await response.json();

      if (result.success) {
        // 设置token
        const wolfUserGoods = result.result.wolfUserGoods;
        console.log("response-wolfUserGoods", wolfUserGoods);
        return wolfUserGoods;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 获取抽奖数据列表 */
export const queryWolfLotteryGoodsList = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.queryWolfLotteryGoodsList, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 设置token
        //返回数据参照  WolfUserGoods.ts
        const wolfLotteryGoodsList = result.result.wolfLotteryGoodsList;
        console.log("response-accountList", wolfLotteryGoodsList);
        return wolfLotteryGoodsList;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 获取用户兑换上链历史记录 */
export const getWolfUserGoodsToChainList = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.getWolfUserGoodsToChainList, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        const wolfUserGoodsToChainList = result.result.wolfUserGoodsToChainList;
        console.log("response-wolfUserGoodsToChainList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 获取用户兑换上链历史记录 */
export const getNftToChainList = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.getNftToChainList, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        const getNftToChainList = result.result.getNftToChainList;
        console.log("response-getNftToChainList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 商品兑奖上链 */
export const reward = async (goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.reward, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "goodsName": goodsName,
      }),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 抽奖结果数据
        const wolfUserGoodsToChain = result.result.wolfUserGoodsToChain;
        console.log("response-wolfUserGoodsToChain", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 合成物品 */
export const synthesis = async (goodsName: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.synthesis, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "goodsName": goodsName,
      }),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 抽奖结果数据
        const wolfUserGoodsResult = result.result.wolfUserGoodsResult;
        console.log("response-wolfUserGoodsResult", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}





/* 抽奖 */
export const doLottery = async (times: number) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.doLottery, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "times": times,
      }),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 抽奖结果数据
        const wolfLotteryGoodsResultList = result.result.wolfLotteryGoodsResultList;
        console.log("response-wolfLotteryGoodsResultList", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 放置土地 */
export const putLand = async (landName: string, animals: string, shit: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.putLand, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "landName": landName,
        "animals": animals,
        "shit": shit
      }),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-wolfUserGoodsResult", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 释放土地 */
export const reapingLand = async (landId: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.reapingLand, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "landId": landId,
      }),
    })

    if (response.status === 200) {

      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-wolfUserGoodsResult", result.result.wolfLandGoodsResultList);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 获取用户土地放置记录 */
export const getLandGameList = async (startIndex = 0, endIndex = 6) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.getLandGameList, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        const landGameList = result.result.landGameList;
        console.log("response-landGameList", landGameList);
        return landGameList;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}
/* 获取用户扩充土地的数量*/
export const getExpandNumber = async () => {
  const XAccessToken = localStorage.getItem('XAccessToken');

  if ((XAccessToken)) {
    const response = await fetch(`${API_CONFIG.getExpandNumber}`, {
      method: 'GET',
      headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json;charset=UTF-8',
      }
    });
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        console.log("response-getExpandNumber", result);
        return result?.result || 0;
      }
    } else if (response.status === 401) {
      await loginOut();
      throw new Error(ERRORS.SESSION_EXPIRED);
    }
  }
};



/* 放置战斗动物 */
export const putArena = async (animalName: string, weapons: string, position: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.putArena, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "animalName": animalName,
        "weapons": weapons,
        "position": position
      }),
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-putArena", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 获取用户战斗动物放置记录 */
export const getWolfArenaGameList = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.getWolfArenaGameList, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        const wolfArenaGameList = result.result.wolfArenaGameList;
        console.log("response-wolfArenaGameList", wolfArenaGameList);
        return wolfArenaGameList;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


/* 获取市场列表 */
export const marketList = async (params: any, pageNo: string, pageSize: string, desc: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.marketList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    url += '&desc=' + encodeURIComponent(desc);
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 获取市场列表 */
export const pvpList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.pvpList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}






/* 领取空投 */
export const userWithdrawal = async (Withdraw: Withdraw) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.userWithdrawal, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(Withdraw)
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-Withdraw", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}


export const createWithdrawPidao = async (Withdraw: Withdraw) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.createWithdrawPidao, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(Withdraw)
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-Withdraw", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}




/* 领取空投 */
export const airDrop = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */

  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.airdrop, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-airDrop", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}



/* 获取质押数据 */
export const getStakeDate = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');


  if ((XAccessToken)) {
    const response = await fetch(API_CONFIG.getStakeDate, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {
        // 放置结果数据
        console.log("response-getStakeDate", result);
        return result;
      } else {
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      console.error(response)
    }
  }

}






/* 获取市场列表 */
export const buildList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.buildList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}






/* NFT查询 */
export const nftWithdraw = async (goodsId: string) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象

    const response = await fetch(API_CONFIG.nftWithdraw, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "goodsId": goodsId,
      }),
    })


    if (response.status === 200) {
      const result = await response.json();
      return result;
    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }

    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }

}
/*buybackConfig回购配置查询 */
export const buybackConfig = async () => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象

    const response = await fetch(API_CONFIG.buybackConfig, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }
    })
    if (response.status === 200) {
      const result = await response.json();
      return result;
    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }

    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }

}




/* 回购 */
export const buyback = async (buybackOrder: BuybackOrder) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象

    const response = await fetch(API_CONFIG.buyback, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(buybackOrder),
    })


    if (response.status === 200) {
      const result = await response.json();
      return result;
    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }

    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }

}






/* 账户流水数据 */
export const transactionFlowList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.transactionFlowList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {

        console.log("response-transactionFlowList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}
//建筑贡献表
export const totalContribute = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  if (XAccessToken) {
    let url = API_CONFIG.transactionFlowList;
    if (params) {
      let paramsArray: any[] = [];
      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }
    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        console.log("response-transactionFlowList", result);

        const columns = Object.keys(result.data[0]);
        const columnSums: { [key: string]: number } = {};
        columns.forEach((column) => {
          const sum = result.data.reduce((total: number, row: number) => {
            return total + (row[column] || 0);
          }, 0);
          columnSums[column] = sum;
        });
        return columnSums;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }
}


/* 获取市场贩卖商品列表 */
export const wolfTownStoreList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.wolfTownStoreList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        console.log("response-marketList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}
/* 获取个人挂售列表 */
export const myList = async (params: any, pageNo: string, pageSize: string) => {
  const XAccessToken = localStorage.getItem('XAccessToken');
  /*   console.log("XAccessTokenuiduid", XAccessToken, "uid", uid); */
  if ((XAccessToken)) {

    let url = API_CONFIG.myList;
    if (params) {
      let paramsArray: any[] = [];
      //拼接参数

      Object.keys(params).forEach(key => paramsArray.push(key + '=' + (typeof params[key] == 'undefined' ? "" : params[key])))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }

    url += '&pageNo=' + pageNo
    url += '&pageSize=' + pageSize
    const response = await fetch(url, {
      method: 'get', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
      const result = await response.json();
      if (result.success) {

        console.log("response-myList", result);
        return result;
      }
    } else if (response.status === 401) {
      await loginOut()
      throw new Error(ERRORS.SESSION_EXPIRED)
    }
  }

}






let DEBUG_ADDRESS = '';

export const WolfConfig = () => {
  useEffect(() => {
    console.log("document", document);
    global.document = window.document;



    // eslint-disable-next-line no-restricted-globals
    const url = new URLSearchParams(location.search || '');
    const observation = url.get('observation');
    if (observation) DEBUG_ADDRESS = observation;
  })
}





const IsTest = process.env.NEXT_PUBLIC_CHAIN_ID == "97";
const IsDevelopment = process.env.NODE_ENV === 'development';
export const Constants = {
  ArenaHistoryBlock: (24 * 60 * 60 * 30) / 3,
  DEBUG_ADDRESS,
  IsDevelopment,
  DEFAULT_SEED: '0x0000000000000000000000000000000000000000000000000000000000000000',
  /**
   * 每个邀请最多可持续的时间 (hours)
   */
  INVITATION_DURATION: 72,

  BUYURL: IsTest ? "https://icecreamswap.com/swap?chainId=1116&outputCurrency=0x28479bd37A96a1FDdC2984E032f4d98c089D0e02" : "https://icecreamswap.com/swap?chainId=1116&outputCurrency=0x28479bd37A96a1FDdC2984E032f4d98c089D0e02",

  IsTest,
  BASE_URL: 'https://app.wolftown.world/animals',
  BASE_IMAGE_URL: 'https://app.wolftown.world/images/animals',
  API_SERVE: 'https://app.wolftown.world',
  OPENSEA_URL: 'https://opensea.io',
  Chain: {
    ID: '1116',
    PRC: 'https://rpc.coredao.org/',
    Name: 'Core Chain Mainnet',
    nativeCurrency: {
      name: 'Core',
      symbol: 'Core',
      decimals: 18,
    },
    ScanView: 'https://scan.coredao.org',
    AddressView: 'https://bscscan.com/address',
    TxView: 'https://bscscan.com/tx',
  },

  emergencyWolfLeave: false,

  Building: {
    OwnershipTotal: 10000000,
  },
  Contract: {
    BankAddress: '0x1f0c693F5cD661126e43b25a96FA703a0C8F2543',
    WTWool: '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
    /* 0xEa38779a32C2f044719FfE5aF8f4D9DE9D6757f6 */
    WTMilk: '0x60Ca032Ba8057FedB98F6A5D9ba0242AD2182177',
    Random: '0x536D40ab94F323b3CBF50DFf24d6fa636Bc170c3',
    /* 0xc7ba9e206cBE7016F2771C9FFc95702DcbbB257b */
    WTAnimal: '0x14f112d437271e01664bb3680BcbAe2f6A3Fb5fB',
    OldWTAnimal: '0xE686133662190070c4A4Bea477fCF48dF35F5b2c',
    Barn: '0x143E742f319d1FE0d1a825d451fc937B5A706702',
    /* 0x10A6DC9fb8F8794d1Dc7D16B035c40923B148AA4 */
    OldBarn: '0x58eaBB38cc9D68bEA8E645B0f5Ec741C82f2871B',
    OldBarnBUG: '0x386500b851CA1aF027247fa8Ab3A9dDd40753813',
    V1AnimalTransfer: '0xCe487D0Ab195D28FE18D5279B042498f84eb051F',
    WoolfTownAPI: '0x301216c75E8af09a3785c9fE7077c543eBB77B6F',
    SkillManager: '0xAef63919ac27d048d9e0c31da474AD0FEedB141a',
    BuildingGameManager: '0xbA58c345cA328F8bfA6A5607a15C2128CC6fBE61',
    WTOwnershipDeed: '0xb61afda2288C4E8AC896B6d4E78BC0ca0C5D98DC',
    WTOwnershipDeedURI: '0x71C0d09D512Fd51BCC96d3ee380a51448635d7DA',
    ForestExploration: '0x6fbaAAF642D9d4A9fC0d2270035D91e100B4B3bC',
    Fight: '0x9851E7eFc67F48E52E98454149793B4eA219F1c5',
    Arena: '0x5f189c322d829aD8F3901A17c54DcC893345d8eB',
    BuildingStakeManager: '0x7c176E44B6e925E8b5F0Fafc7b2Ec2876A6fE8aD',
    WT_LP_USDT: '0xcAad85D48f31177040E25048FF4dd875eAE9Dea7',
    WT_LP_BNB: '0xe9C7bc98901d1B71d63902602Bff6E37dCdE79fC',
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    DONATE: '0x63aA9b1f4233ED5F980c3725A19677d60F18f265',
    DONATEPERSALE: '0x8C01174b906Dd9Cf1c198C2b8322b7A5051e8E1B',
    DONATEPLEDEG: '0x8B8eC82e8F1a97695DDe82cb9CF5C4bB0D531fe0',
    BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    /* BUSD: '0x205815495B879D2D67bE4646e791Eca3d434b303', */
    GODZ: '0xF0A8EcBCE8caADB7A07d1FcD0f87Ae1Bd688dF43',
    NEWGODZ: '0xeb90A6273F616A8ED1cf58A05d3ae1C1129b4DE6',
    WTanimalPlus: '0x85849483E38FB4EF8bb4400c868916c75071b6f4',
    MintInvite: "0x6bAEf88ea37eEDE9f6407A4dE2BF81f3D4035A3A",
    Hero: "0x38Fd96AFe66CD14a81787077fb90e93944Dd75f8",
    DONATEPLEDEGV2: '0x162497dFee63F33f7b3e64A2f3Ceec9D0f9E2942',
    DONATEPLEDEGV3: '0xe24ea9a698afd4ba581c1e5de47dd71ac3c15166',
    heroMint: '0x931a3ecc63160e834dab61599fd9b6797c876857',
    /* LPStakeAdmin: */
    LPStake: '0x01E35675Daa229Cbd59AAF747061EFD3C1fa2D9B',
    BRX_LP_USDT: '0x92dFb5e101d34f4718EddEf238bA5f0C5835d6aD',
    BRX: '0x28479bd37A96a1FDdC2984E032f4d98c089D0e02',
    BRX_REDMINE: '0x4d9C31F0113c30458C403cFb7727E423D5B82478',
    WCORE: '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f',
    CUST: '0x9Ebab27608bD64AFf36f027049aECC69102a0D1e',
    PIDAO: '0x28479bd37A96a1FDdC2984E032f4d98c089D0e02',
    VAULT: '0x66D9739f206D5d7943a60f8b10cd7D9EDbbb5531',
  },
};
export const AbiConfig: Record<keyof typeof Constants.Contract, any> = {
  BankAddress: require('config/abi/wtWool'),
  WTWool: require('config/abi/wtWool'),
  WTMilk: require('config/abi/wtWool'),
  Random: require('config/abi/wtWool'),
  WTAnimal: require('config/abi/wtAnimal.json'),
  OldWTAnimal: require('config/abi/wtAnimal.json'),
  Barn: require('config/abi/wtBarn.json'),
  OldBarn: require('config/abi/wtBarn.json'),
  OldBarnBUG: require('config/abi/wtBarn.json'),
  V1AnimalTransfer: require('config/abi/V1AnimalTransfer.json'),
  WoolfTownAPI: require('config/abi/WoolfTownAPI.json'),
  SkillManager: require('config/abi/SkillManager.json'),
  BuildingGameManager: require('config/abi/BuildingGameManager.json'),
  WTOwnershipDeed: require('config/abi/WTOwnershipDeed.json'),
  WTOwnershipDeedURI: require('config/abi/WTOwnershipDeed.json'),
  ForestExploration: require('config/abi/ForestExploration.json'),
  Arena: require('config/abi/Arena.json'),
  Fight: require('config/abi/Fight.json'),
  BuildingStakeManager: require('config/abi/BuildingStakeManager.json'),
  WT_LP_USDT: require('config/abi/lp.json'),
  WT_LP_BNB: require('config/abi/lp.json'),
  USDT: require('config/abi/wtWool'),
  DONATE: require('config/abi/donate.json'),
  DONATEPERSALE: require('config/abi/donatepersale.json'),
  DONATEPLEDEG: require('config/abi/donateForPledge.json'),

  BUSD: require('config/abi/wtWool'),
  GODZ: require('config/abi/wtWool'),
  NEWGODZ: require('config/abi/wtWool'),
  WTanimalPlus: require('config/abi/wtWool'),
  MintInvite: require('config/abi/MintInvite.json'),
  Hero: require('config/abi/wtAnimal.json'),
  DONATEPLEDEGV2: require('config/abi/donateForPledge.json'),
  DONATEPLEDEGV3: require('config/abi/donateForPledge.json'),
  heroMint: require('config/abi/raidHeroMint.json'),
  LPStake: require('config/abi/LPStake.json'),
  BRX_LP_USDT: require('config/abi/lp.json'),
  BRX: require('config/abi/pi.json'),
  BRX_REDMINE: require('config/abi/redmine.json'),
  WCORE: require('config/abi/wtWool'),
  CUST: require('config/abi/wtWool'),
  PIDAO: require('config/abi/wtWool'),
  VAULT: require('config/abi/Vaut.json')

};
if (!IsDevelopment) {
  console.log = () => null;
  console.error = () => null;
  console.info = () => null;
}
if (IsTest) {
  Object.assign(Constants, {
    BASE_URL: 'https://app.test.wolftown.world/animals',
    BASE_IMAGE_URL: 'https://app.test.wolftown.world/images/animals',
    API_SERVE: 'https://app.test.wolftown.world',
    Chain: {
      ID: '97',
      PRC: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      Name: 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'tBNB',
        symbol: 'tBNB',
        decimals: 18,
      },
      ScanView: 'https://testnet.bscscan.com',
      AddressView: 'https://testnet.bscscan.com/address',
      TxView: 'https://testnet.bscscan.com/tx',
    },
    Contract: {
      // api
      WoolfTownAPI: '0xbFE5a21f0c153144BE0c65BE961b01Bf52EE1B0f',

      // contract
      WTWool: '0x808156c8510f3b07209762d1357da452Bc740067',
      WTMilk: '0x98949d35a2cb9400643B8FB5393266eD9CDb0f18',
      Random: '0x5b52eD1Eed64A5b98b244D090682e011F6f502be',
      /*  WTAnimal: '0x0E606A0c4042C4183eD183f9245cEb616ec4D458', */
      WTAnimal: '0x51D4767a6177A0f20c1c04f5A14724E1CBb90603',
      OldWTAnimal: '0xBE66EA1D143700857A5686eCA9D91d5BC6A3BA36',
      Bannable: '0x901E981335272C3817663692AdE7A2b9f7Af1430',
      /* Barn: '0x3d227278b0c500918ad5D1B70a04fDb2cE63F645', */
      Barn: '0x0d9d038f279abe87051095BEEa88474ef084917d',
      OldBarn: '0xa87FA735B0e2ec0ECae06b7552D237BBe044ef7B',
      OldBarnBUG: '0xB2b4bc0ce285CFe10Bf93e09E0fDEB93f41d1fdB',
      V1AnimalTransfer: '0x74aBd8Ea56510D49c373CBc99ECB3d1e204B479F',
      WoolPool: '0x0e31631B9C0d59c946e6F08d1c9A8Bc4e567C413',
      SkillManager: '0x784Ffbb7E630F958Ca0586B0487Edb2cBfe249CD',
      BuildingGameManager: '0x5CA7800F3C8c3E98C769DC5d5b4d119aA2ac1728',
      BuildingStakeManager: '0x9B2F1d8Ddcf9d19505bAaF0a71E670b0eac7D424',
      WTOwnershipDeed: '0xbaE4e86071f5FDBac225546aaC76B9Ae587051Dc',
      WTOwnershipDeedURI: '',
      ForestExploration: '0x8844FE9f80c5C37E7C5BE678A97c36064986334e',
      Arena: '0x176f53ADc7d475356488BB0EA658Baa5507D1E1b',
      Fight: '0x6f4c48B1E47Db04564a067F2D0ac34Bc76Da7C2d',
      WT_LP_USDT: '0x03BAE7A09f02C0D63dfA1C7196E0A8D60aeBcf81',
      WT_LP_BNB: '0x7C7747716545F19cdF0257A23C5dE2fa184BDb1D',
      USDT: '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
      DONATE: '0x63aA9b1f4233ED5F980c3725A19677d60F18f265',
      DONATEPERSALE: '0x8CE89399c89585A2b25485732e470E20b6fA433D',
      DONATEPLEDEG: '0x8B8eC82e8F1a97695DDe82cb9CF5C4bB0D531fe0',

      BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      GODZ: '0xF0A8EcBCE8caADB7A07d1FcD0f87Ae1Bd688dF43',
      NEWGODZ: '0xeb90A6273F616A8ED1cf58A05d3ae1C1129b4DE6',
      WTanimalPlus: '0x85849483E38FB4EF8bb4400c868916c75071b6f4',
      MintInvite: "0x6bAEf88ea37eEDE9f6407A4dE2BF81f3D4035A3A",
      Hero: "0x38Fd96AFe66CD14a81787077fb90e93944Dd75f8",
      DONATEPLEDEGV2: '0x162497dFee63F33f7b3e64A2f3Ceec9D0f9E2942',
      DONATEPLEDEGV3: '0xe24ea9a698afd4ba581c1e5de47dd71ac3c15166',
      heroMint: '0x931a3ecc63160e834dab61599fd9b6797c876857',
      LPStake: '0xEEBabF6C3958504B1089D565d358871bB6E666Cf',
      BRX_LP_USDT: '0x0B7753127fE0cD8fA8A0A06ABEC823e0aD193A2E',
      BRX: '0x28479bd37A96a1FDdC2984E032f4d98c089D0e02',
      BRX_REDMINE: '0x4d9C31F0113c30458C403cFb7727E423D5B82478',
    },
  });
}


