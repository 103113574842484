export default {
  masterChef: {
    97: '',
    1116: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    1116: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1116: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    1116: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    1116: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeProfile: {
    1116: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    1116: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    1116: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    1116: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    1116: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    1116: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    1116: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    1116: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  tradingCompetitionMobox: {
    1116: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
  },
  tradingCompetitionMoD: {
    1116: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  easterNft: {
    1116: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    1116: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '',
  },
  predictions: {
    1116: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    1116: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    1116: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    1116: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    1116: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    1116: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    1116: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    1116: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  galaxyNftClaiming: {
    1116: '0x2D18f2d27D50C9b4013DEBA3D54f60996bD8847E',
    97: '',
  },
  nftMarket: {
    1116: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    1116: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    1116: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  wtAnimal: {
    1116: '0x14f112d437271e01664bb3680BcbAe2f6A3Fb5fB',
    97: '0x51D4767a6177A0f20c1c04f5A14724E1CBb90603',
  },
  barn: {
    1116: '0x1537AB8542b60C2cdAF49050cEBB533F54C3e593', // StakingPhase1
    97: '0x385d203eAc003b56Af9fE892295c5837b329b587',
  },
  marketplace: {
    1116: '0xf845180Eb164d41B61fF0a7231275633Df924971',
    97: '0x6bCA0Bc34e101fE717265770664F45bf154eBc37',
  },
  hero: {
    // 1116: '0xd09e486223CDf18d4a2C1A072900C8Dc453b672A',
    1116: '0x38Fd96AFe66CD14a81787077fb90e93944Dd75f8',
    31337: '0x4A84ECFD55FdD6A05d6AF7a08e3854668EA4E657'
  },
  heroMint: {
    // 1116: '0x00D26F3938e5C2d375dAABD4A9eD17a75Bb3F313',
    1116: '0x931a3eCc63160E834DaB61599FD9B6797c876857',
    31337: '0xff14451B51B04FC9f83f98ea534473Df8619E9b5'
  },
  warrior: {
    1116: "0x0d9eb3079Dbf1Df9715B47DA98a3BacaeD28c49C",
    97: ""
  },
  blackCard: {
    1116: "0xFBB500f9F36C859251BE621Ba6a8E399E6dE64F7",
    97: ""
  },
  warriorMint: {
    1116: "0x2318D89950de0744542262080A3A528e5E7eac7b",
    97: ""
  }
}
