function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  max-width: 100%;\n  width: 100%;\n\n  tbody tr:last-child {\n    ",
        " {\n      border-bottom: 0;\n    }\n  }\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space } from "styled-system";
import { Td } from "./Cell";
var Table = styled.table.withConfig({
    componentId: "sc-4fe367f7-0"
})(_templateObject(), Td, space);
export default Table;
